function openMenu(){
	$('nav.site-nav').addClass('visible');
	setTimeout(function(){$('nav.site-nav').addClass('active');}, 200)
	setTimeout(function(){
		$('a#menu-closer').addClass('active');
		$('ul#primary-navigation').addClass('active');
	}, 500)
}
function closeMenu(){
	$('a#menu-closer').removeClass('active');
	$('ul#primary-navigation').removeClass('active');
	$('nav.site-nav').removeClass('active');
	setTimeout(function(){$('nav.site-nav').removeClass('visible');}, 500)
}
$(document).ready(function(){
	$('.banner-slider').slick({
		arrows:false,
		dots: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 1000,
		fade: true,
		pauseOnHover:false,
		cssEase: 'linear'				
	});
	
	$("#menu-toggle").on('click', function(e){
		e.preventDefault();
		openMenu();
	});
	
	$("#menu-closer").on('click', function(e){
		e.preventDefault();
		closeMenu();
	});

});



var header = $('#site-header');
var navbarHeight = header.outerHeight();
didScroll=false;
$(window).scroll(function(event){
    didScroll = true;
});
setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 100);

function hasScrolled(){
	var st = $(this).scrollTop();    
    if (st > navbarHeight){
		header.addClass('scrolled');    
    }else{
	    header.removeClass('scrolled');    
	}
}

// Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
      && 
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: (target.offset().top)
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });